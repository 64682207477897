import React from "react";
import { BatchUpload } from './BatchUpload'
import "./HierarchySet.css";
import {INGESTION_STATUS,INGESTION_TYPE } from "../../consts";
import LoaderComponent from "../util/Loader";

export class HierarchyMapping extends React.Component {
  render() {
    const { hierarchyDataMap, handleHierarchyFiles, uploadData, locationofHierarchyFiles,ingestion_status,ingestion_type,ingestion_id,
      handleUploadedFileEdit, handleUploadBackButton, data_source_type, handleUploadBatchData , ingestion_upload_files,hierarchy_file_folder } = this.props;
      const ingestionStatusAndType = ingestion_status === INGESTION_STATUS && ingestion_type === INGESTION_TYPE
     let isUploadedFilesArray =  hierarchyDataMap && hierarchyDataMap.length > 1 && hierarchyDataMap.map((obj, i) => {
        if (obj.hasOwnProperty("filename") && obj.filename !== "") return true
      })
     let isUploadedFiles = isUploadedFilesArray && isUploadedFilesArray.every((e)=>e);
      if(isUploadedFiles && ingestion_upload_files){
      const ingestion_ids = Object.keys(ingestion_upload_files)
      var ingestion_match_id = ingestion_ids.filter((id)=> id == ingestion_id)  
     }
    let display_ingestion_upload_files = ingestion_match_id && ingestion_match_id.length > 0 ? ingestion_upload_files[ingestion_match_id] : hierarchyDataMap
      return (
      <div>
        {
          data_source_type === "BATCH" ? <BatchUpload hierarchyDataMap={hierarchyDataMap} handleUploadedFileEdit={handleUploadedFileEdit}
            handleHierarchyFiles={handleHierarchyFiles} handleUploadBatchData={handleUploadBatchData}
          /> :
            <div>
              <div className="input-container">
                <i className="fa fa-file-excel icon"></i>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => locationofHierarchyFiles(e.target.value)}
                  value={hierarchy_file_folder}
                  placeholder="Folder where all files are located at!"
                />
              </div>
              {/* <div className="or-container">
                <hr className="hr-text" data-content="OR" />
              </div> */}
              {/* <div className="datamap-container">
                <div className="hierarchy-data table-header">
                  <div className="h-row">
                    <b>Hierarchy File Columns</b>
                  </div>
                  <div className="h-file">
                    <b>Hierarchy File</b>
                  </div>
                </div>
                {            
                  
                  display_ingestion_upload_files && display_ingestion_upload_files
                    .map((obj,i)=>{
                        if (obj.hasOwnProperty("filename") && obj.filename !== "") {
                          return (
                            <div className="hierarchy-data" key={i}>
                              <div className="h-row">
                                {obj.actual_label} [ {obj.label} ]
                              </div>
                              <div className="h-file">
                                Uploaded File: <b> {obj.filename} </b>
                                {
                                  // ingestionStatusAndType ? "": 
                                  <span>
                                  <a onClick={(e) => handleUploadedFileEdit(obj)}>edit</a>
                                </span>
                                }
                              </div>
                            </div>
                          );
                        }else {
                          return (
                            <div className="hierarchy-data" key={i}>
                              <div className="h-row">
                                {obj.actual_label} [ {obj.label} ]
                              </div>
                              <div className="h-file">
                                <input
                                  type="file"
                                  accept=".xls,.xlsx,.csv"
                                  id="file"
                                  name="file"
                                  onClick = {(e) => e.target.value = null}
                                  onChange={(e) => handleHierarchyFiles(obj.label, e)}
                                  required
                                />
                              </div>
                            </div>
                          );
                        }
                      })
                    }
              </div> */}
              <div className="upload-data-btn">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => uploadData(e)}
                  // disabled={ingestionStatusAndType}
                >
                  Upload Data
          </button>
              </div>

            </div>
        }
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleUploadBackButton(this.props)}
        >
          Previous
          </button>
          <LoaderComponent isLoaderLoading={this.props.isLoaderLoading}/>
      </div>
    );
  }
}
