import React, { Component } from "react";
import Form from "react-jsonschema-form";
import "./rca.css";
import { toast } from "react-toastify";
import { handleErrors } from "./ui_utils";
import { CONFIG_MS } from "../consts";
import Select from "react-select";
import { CustomSelectBox } from "./CustomSelectBox";



export class RCA extends Component {
  componentDidMount = () => {
    this.props.fetchCausalExperiments();
    this.props.fetchAnomalyExperiments();
    this.props.fetchUsers()
    this.props.getAllAnomalyAlgorithms();
    this.props.fetchDataSources();
  };
  constructor(props) {
    super(props);
  }

  render() {
    const {
      run_rca,
      pipeline_user_map,
      users,
      map_user,
      pipelineName,
      causalExprimentsRCA,
      handleCausalExpCallRCA,
      handleAnomalyExpCallRCA,
      causalRunsRCA,
      anomalyRunsRCA,
      anomalyExprimentsRCA,
      handleSelectCausalRunRCA,
      handleSelectAnomalyRunRCA,
      rcaMaxHopsSchema,
      handleRcaMaxHopsChange,
      selected_rca_anomaly_label,
      selected_rca_causal_exp_label,
      maxHopsData,
      handleLookupRange,
      lookup_range, traversal_kpis_list,
      handleRcaKpiSelection, traversal_kpis , datasourcesListRCA,
      rcaDisplayAnomalyProjects,rcaDisplayCausalProjects,rca_impact_count
    } = this.props;

    if (rcaDisplayAnomalyProjects && rcaDisplayAnomalyProjects.length > 0) {
      var anomalyExperimentsList = rcaDisplayAnomalyProjects.map((obj, i) => {
        return {
          value: obj.experiment_id,
          label: obj.experiment_name,
        };
      });
    }
    let anomalyKpiOptions = traversal_kpis_list && traversal_kpis_list.length > 0 &&
      traversal_kpis_list.map((kpi, index) => {
        return {
          id: index,
          name: kpi
        }
      })

    if (anomalyRunsRCA) {
      var anomalyRunsList =
        anomalyRunsRCA.length > 0 &&
        anomalyRunsRCA.map((obj, i) => {
          return {
            value: obj.run_id,
            label: obj.run_name,
          };
        });
    }

    if (rcaDisplayCausalProjects && rcaDisplayCausalProjects.length > 0) {
      var causalExperiments_display =
      rcaDisplayCausalProjects.length > 0 &&
      rcaDisplayCausalProjects.map((obj, i) => {
          return {
            value: obj.experiment_id,
            label: obj.experiment_name,
          };
        });
    }

    if (causalRunsRCA) {
      var causal_runs_display =
        causalRunsRCA.length > 0 &&
        causalRunsRCA.map((obj, i) => {
          return {
            value: obj.run_id,
            label: obj.run_name,
          };
        });
    }
    let AnomalyalgoTypes = this.props.anomaly_algo_types && this.props.anomaly_algo_types.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    let userAlgoList = this.props.userAlgo && this.props.userAlgo.map((item, i) => {
      return {
        value: item.algorithm_name,
        label: item.display_name,
      };
    });
  const display_datasourcesListRCA =   datasourcesListRCA && datasourcesListRCA.filter(ds => ds.data_source_status !== 'LAST INGESTION FAILED').
    map(ds => {
      return {
        value: ds.display_name,
        label: ds.display_name,
      };
    });
    return (
      <div className="rca_body">
        <div className="rca_heading">RCA and Impact Inference Generation</div>
        <fieldset>
          <legend>Select Data Source :</legend>
          <div className="exp_run_container">
            <div className="exp_select">
              <label>Data Source</label> &nbsp;
              <div className="experiment-select">
              <Select
                options={display_datasourcesListRCA && [{value:"", label: 'Select....' } , ...display_datasourcesListRCA]}
                //value={selected_rca_anomaly_label}
                onChange={this.props.handleDataSourceChangeRCA}
              />
              </div>
            </div>
            <div className="run_select flex_end m-0">
              {rca_impact_count && rca_impact_count.rca_status !== "No RCAs Found" ? 
                <div className="rca_impact_count">
                  <div class="rca_count"> 
                    <div><b>RCA Count</b></div> 
                    <div className="font_20">{rca_impact_count.rca_count}</div>
                  </div>
                  <div class="impact_count"> 
                    <div><b>Impact Count</b></div> 
                    <div className="font_20">{rca_impact_count.impact_coun}</div>
                  </div>
                </div>
                : ""}
            </div>
          </div>
          
        </fieldset>
        <fieldset>
          <legend>Select Anomaly Project & Experiment:</legend>
          <div className="exp_run_container">
            <div className="exp_select">
              <label>Anomaly Project</label> &nbsp;
              <div className="experiment-select">
                <Select
                  options={anomalyExperimentsList}
                  value={selected_rca_anomaly_label}
                  onChange={handleAnomalyExpCallRCA}
                />
              </div>
            </div>
            <div className="run_select">
              <label>Anomaly Experiment</label> &nbsp;
              <div className="experiment-select">
                <Select
                  options={anomalyRunsList}
                  value={this.props.selected_anomaly_run}
                  onChange={handleSelectAnomalyRunRCA}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Select Causal Project & Experiment:</legend>
          <div className="exp_run_container">
            <div className="exp_select">
              <label>Causal Project</label> &nbsp;
              <div className="experiment-select">
                <Select
                  options={causalExperiments_display}
                  value={selected_rca_causal_exp_label}
                  onChange={handleCausalExpCallRCA}
                />
              </div>
            </div>
            <div className="run_select">
              <label>Causal Experiment</label> &nbsp;
              <div className="experiment-select">
                <Select
                  options={causal_runs_display}
                  value={this.props.selected_causal_run}
                  onChange={handleSelectCausalRunRCA}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>RCA and Impact Analysis Configuration:</legend>
          <div className="rca_form">
            <div className="lookup-range-container">
              <label>Lookup Range</label>
              <div className="lookup-range-input">
                <input
                  type="text"
                  pattern="[0-9]*"
                  onInput={(e) => handleLookupRange(e)}
                  value={lookup_range}
                />
              </div>
            </div>
            <Form
              schema={rcaMaxHopsSchema}
              formData={maxHopsData}
              onChange={handleRcaMaxHopsChange}
            >
              <div>
                <button type="submit" className="hidden">
                  Submit
                </button>
              </div>
            </Form>
            <div className="rca_kpis">
              <label>Start Traversal from</label>
              <CustomSelectBox
                selectedValues={traversal_kpis}
                multiSelectOptions={anomalyKpiOptions}
                onChangeValues={handleRcaKpiSelection}
                isOpen={false}
                valueKey="id"
                labelKey="name"
                isMultiple={true}
                includeSelectAll={true}
                includeFilter={true}
                selectBoxHeight={180}
              />
            </div>
            <div className="user">
              <label>Select User</label>
              <CustomSelectBox
                selectedValues={pipeline_user_map}
                multiSelectOptions={users}
                onChangeValues={map_user}
                isOpen={false}
                valueKey="id"
                labelKey="name"
                isMultiple={false}
                includeSelectAll={true}
                includeFilter={true}
                selectBoxHeight={180}
              />
            </div>
          </div>
        </fieldset>
        <React.Fragment>
          <div className="run_rca_btn">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={(e) => run_rca(e)}
            >
              Execute RCA &amp; Impact
            </button>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
