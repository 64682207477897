import React, { Component } from "react";
import { NavLink, Route , Redirect , Switch} from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/logo/Acc_Logo_White_Purple_RGB.png";
import { INITIAL_STATE } from "../consts";
import "./AnalystView.css";
import { Anomaly } from "./Anomaly";
import { Summerization } from "./Summerization";
import { Causal } from "./Causal";
import { FAQ } from "./FAQ";
import {PageNotFound} from "./PageNotFound"
import  Notifications  from "./Notifications";
import "./Home.css";
import { Profiles } from "./Profiles";
import { ProtectedRoute } from "./ProtectedRoute";
import { RCA } from "./RCA";
import "./step.css";
import "./notification_pill.css";
import { handleErrors } from "./ui_utils";
import { Popup } from "./util/popup/Popup";
import { DataSource } from "./DataSource";
import { sendAmplitudeData } from './util/amplitude';
import { RUN_RCA } from './util/consts_amplitude'
import { fetchCall } from "./util/util";
import { ArchievedDataSourcesList } from "./DataSourceComponents/ArchievedDataSourcesList";
import { userService } from "../_services";
import { PrivacyPolicyModal } from "./util/Modal/privacyModal/PrivacyPolicyModal";
import { PredictiveComponent } from "./PredictiveComponent";
import { AppProvider } from "./context/AppContext";
import { FeedbackMining } from "./FeedbackMining";
import { CSVReportHandler } from "./CSVReportHandler";
import { ExternalDataCopier } from "./ExternalDataCopier";
import { WhatIfScreen } from "./whatIfScreen";
import { ActionRecommendation } from "./ActionRecommendation";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentWillUnmount() {
    this.timer = null;
  }
  updateNotificationCount = () => {
    const notification_count = this.state.notification_count;
    fetchCall(`/app/api/notifications/count?user_uuid=${JSON.parse(this.state.user_uuid)}`, "GET")
      .then((response) => handleErrors(response))
      .then((resp) => {
        if(notification_count != resp){
          this.setState({
            notification_count: resp,
          });
        }
        
      })
      .catch((error) => {
        console.log("Unable to Fetch Notifications");
      });
  };
  
  componentDidMount() {
  
    this.timer = setInterval(()=> this.updateNotificationCount(), 50000);
    this.authValidate();
  }
  
  fetchUsers = () => {
    fetchCall(`/app/api/user/`,"GET")
      .then(handleErrors)
      .then((users) => {
        let user_vals =
          users &&
          users.map((user, i) => {
            return {
              id: i,
              name: user,
            };
          });
        this.setState({
          users: user_vals,
        });
      });
  };
  togglePopup = (text) => {
    this.setState({
      showPopup: !this.state.showPopup,
      popuptext: text,
    });
  };
  fetchDataSources = () => {

    let toastId = null;
    toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/datasources`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          pipeline: resultData,
        });
        toast.update(toastId, {
          render: "Data Source fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  fetchCausalExperiments = () => {
    let toastId = null;
    toastId = toast("Fetching Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/experiment`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          causalExprimentsRCA: resultData,
          selected_rca_causal_exp_label: null,
          selected_rca_anomaly_label: null,
          selected_anomaly_run: null,
          selected_causal_run: null,
          rcaDisplayAnomalyProjects: null,
          rcaDisplayCausalProjects: null,
          anomalyRunsRCA: null,
          causalRunsRCA: null,
          predictiveKPIList: null,
          period_predictive : null,
          forecasting_length: null,
          selectedPredictiveFrequency: null,
          selected_univariate_algo: null,
          uniVariateAlgoParamData: null,
          selected_multivariate_algo: null,
          multiVariateAlgoParamData: null,
          selected_whatif_algo:null,
          whatIfAlgoParamData:null,
          recommendationAlgoParamData:null,
          target_kpi_model:"",
          target_kpifile_path:"",
          selected_objective_kpi:{},
          selected_driver_kpis:[]
        });
        toast.update(toastId, {
          render: "Causal Projects fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  fetchAnomalyExperiments = () => {
    let toastId = null;
    toastId = toast("Fetching Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/anomaly/experiment`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          anomalyExprimentsRCA: resultData,
        });
        toast.update(toastId, {
          render: "Anomaly Projects fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  handleRcaMaxHopsChange = (form_event) => {
    this.setState({
      maxHopsData: form_event.formData,
    });
  };
  run_rca = () => {
    let anomaly_exp_id = this.state.selected_anomaly_experiment_id;
    let anomaly_exp_name = this.state.selected_anomaly_experiment_name;
    let anomaly_run_id = this.state.selected_anomaly_run_id;
    let anomaly_run_name = this.state.selected_anomaly_run_name;
    let causal_exp_id = this.state.selected_causal_experiment_id;
    let causal_exp_name = this.state.selected_causal_experiment_name;
    let causal_run_id = this.state.selected_causal_run_id;
    let causal_run_name = this.state.selected_causal_run_name;
    let maxHopsData = this.state.maxHopsData;
    let lookup_range = this.state.lookup_range;
    let default_algorithm_rca = {}
    default_algorithm_rca[`algoType`] = this.state.algoType
    default_algorithm_rca[`selected_user_algo`] = this.state.selected_user_algo
    default_algorithm_rca[`algoParamData`] = this.state.algoParamData
    let allIds = [anomaly_exp_id, anomaly_run_id, causal_exp_id, causal_run_id];
    let nullFlag = false;
    let traversal_kpis = this.state.traversal_kpis.map((obj)=>{
      return obj.name
    })
    allIds.forEach((id) => {
      if (id === undefined || id === null || id == "") {
        return (nullFlag = true);
      }
    });
    if (maxHopsData !== undefined) {
      Object.values(maxHopsData).forEach((val) => {
        if (val === undefined) {
          nullFlag = true;
        }
      });
    }
    if (nullFlag) {
      const text = "Please select all combinations";
      this.togglePopup(text);
      return;
    }
    let toastId = null;

    toastId = toast("RCA & Impact execution", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      user_uuid: JSON.parse(this.state.user_uuid),
      user_email: this.state.pipeline_user_map,
      anomaly_experiment_id: anomaly_exp_id,
      causal_experiment_id: causal_exp_id,
      anomaly_run_id: anomaly_run_id,
      causal_run_id: causal_run_id,
      lookup_range: lookup_range,
      rca_hops: maxHopsData.rca_max_hops,
      impact_hops: maxHopsData.impact_max_hops,
      traversal_kpis:traversal_kpis,
      default_algorithm_rca:default_algorithm_rca
    })
    fetchCall(`/app/api/graph/rca/run`,"POST",data)
      .then((response) => handleErrors(response))
      .then((resultData) => {

        sendAmplitudeData(RUN_RCA, {
          'Anomaly project id': anomaly_exp_id, 'Anomaly project name': anomaly_exp_name, 'Anomaly experiment id': anomaly_run_id,
          'Anomaly experiment name': anomaly_run_name, 'Causal Project id': causal_exp_id, 'Causal Project name': causal_exp_name,
          "Causal experiment id": causal_run_id, "Causal experiment name": causal_run_name
        })
        this.setState({
          RCA_OP: resultData,
        });
        toast.update(toastId, {
          render: "Initiated RCA Run",
          type: toast.TYPE.INFO,
          autoClose: true,

        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
        });
      });
  };
  map_user = (e) => {
    this.setState({
      pipeline_user_map: e,
    });
  };
  handleCausalExpCallRCA = (exp_id) => {
    let toastId = null;
    toastId = toast("Fetching Causal Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/experiment/${exp_id.value}/runs`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData.length > 0) {
          this.setState({
            causalRunsRCA: resultData,
            selected_causal_experiment_id: exp_id.value,
            selected_causal_experiment_name: exp_id.label,
            selected_rca_causal_exp_label: exp_id,
          });
          toast.update(toastId, {
            render: "Eperiments fetched successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            autoClose: 1000,
          });
          const text = "Selected Projects doesn't have Experiments";
          this.togglePopup(text);
          return;
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleAnomalyExpCallRCA = (exp_id) => {
    let toastId = null;
    toastId = toast("Fetching Anomaly Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/anomaly/experiment/${exp_id.value}/runs`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData.length > 0) {
          this.setState({
            anomalyRunsRCA: resultData,
            selected_anomaly_experiment_id: exp_id.value,
            selected_anomaly_experiment_name: exp_id.label,
            selected_rca_anomaly_label: exp_id,
            selected_anomaly_run:"",
            traversal_kpis:[]
          });
          let selected_exp_obj = this.state.anomalyExprimentsRCA.filter((item)=>{
            return item.experiment_id == exp_id.value
          })
          this.handleDataSourceCall(selected_exp_obj[0].data_source_id)
          toast.update(toastId, {
            render: "Experiments fetched successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            autoClose: 1000,
          });
          const text = "Selected Projects doesn't have Experiments";
          this.togglePopup(text);
          return;
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleDataSourceCall = (id) => {
    let toastId = null;
    toastId = toast("fetching kpis", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/datasource/${id}`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        this.setState({
          traversal_kpis_list: resultData.user_columns.kpi,
        });
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleRcaKpiSelection = (values) => {
    this.setState({
      traversal_kpis:values
    })
  }
  handleSelectCausalRunRCA = (obj) => {
    this.setState({
      selected_causal_run_id: obj.value,
      selected_causal_run_name: obj.label,
      selected_causal_run:obj
    });
  };
  handleSelectAnomalyRunRCA = (obj) => {
    this.setState({
      selected_anomaly_run_id: obj.value,
      selected_anomaly_run_name: obj.label,
      selected_anomaly_run:obj
    });
  };
  handleCausalPipelineMap = (e) => {
    const pipeline_uuid = e.target.value;
    localStorage.setItem("rcaPipeline", JSON.stringify(pipeline_uuid));
    this.setState({
      rcaPipeline: pipeline_uuid,
    });
  };
  handleLookupRange = (evt) => {
    const lookup_range = evt.target.validity.valid
      ? evt.target.value
      : this.state.lookup_range;

    this.setState({
      lookup_range,
    });
  };
  handleForeCastingLength = (value) => {
    this.setState({
      forecasting_length : value
    });
  }
  handlePredictivePeriod = (value) => {
    this.setState({
      period_predictive : value
    });
  }
  handlePredictiveFrequency = (option) => {
    this.setState({
      selectedPredictiveFrequency: option
    })
  }
  getAllAnomalyAlgorithms = () => {
    fetchCall(`/app/api/algorithm/anomaly`,'GET')
      .then((result) => handleErrors(result))
      .then((resultData) => {
        let algo_types = resultData && resultData.map((obj) => obj.algorithm_category);

        this.setState({
          anomaly_algorithms: resultData,
          anomaly_algo_types: [...new Set(algo_types)],
        });
      })
      .catch((error) => {
        toast("ERROR: " + error, {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
  };
  handleAlgorithmTypeChange = (type) => {
    let userAlgo = this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === type.value
    );
    let selected_user_algo = {};
    selected_user_algo["label"] = "Select...";
    selected_user_algo["value"] = "";
    this.setState({
      userAlgo: userAlgo,
      algoType: type,
      algoParam: {},
      algoData: {},
      algoParamData:{},
      formErrors:[],
      current_algo: "",
      current_algo_id: "",
      selected_user_algo: selected_user_algo,
    });
  };
  handleAlgorithmChange = (label) => {
    let paramObj = this.state.userAlgo.filter(
      (obj) => obj.algorithm_name === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })
    
    let dynamic_drop_down_option_p2p = []
    if (paramObj && paramObj[0].algorithm_name !== "no_anomaly") {
      if (paramObj && paramObj[0].algorithm_category.includes("Peer to Peer")) {
        if (!this.state.anomalyhierarchySelection.label) {
          alert("No Hierarchy Selected");
          return;
        }
        this.get_group_by_level(
          this.state.anomalyhierarchySelection.label, paramObj[0].algorithm_name
        ).then((group_by_level_vals) => {

          dynamic_drop_down_option_p2p = group_by_level_vals;
          if (paramObj && paramObj[0].algorithm_name == "fs_peer_to_peer") {
            param_val.properties.Group_by.enum = dynamic_drop_down_option_p2p;
          } else if (paramObj && paramObj[0].algorithm_name == "peer_to_peer_type_two") {
            param_val.properties.baseline_hierarchy.enum = dynamic_drop_down_option_p2p;
          }
          else if (paramObj && paramObj[0].algorithm_name == "peer_to_peer_type_one") {
            param_val.properties.group_by_column.enum = dynamic_drop_down_option_p2p;
          }
          this.setState({
            algoParam: param_val,
            algoData: JSON.parse(paramObj[0].algorithm_defaults),
            algoParamData:algo_param_all_values,
            required_params:required_params,
            formErrors:[]
          });
        });

      } else {
        this.setState({
          algoParam: param_val,
          algoData: JSON.parse(paramObj[0].algorithm_defaults),
          algoParamData:algo_param_all_values,
          required_params:required_params,
          formErrors:[]
        });
      }
    }
    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].algorithm_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      current_algo_id: paramObj[0].uuid,
      current_algo: paramObj[0].algorithm_name,
      current_algo_display_name: paramObj[0].display_name,
      selected_user_algo: selected_user_algo,
    });
  };
  handleParamChange = (form_event) => {
    this.setState({
       algoParamData: form_event.formData,
       formErrors:form_event.errors
    });
  };
  handleDataSourceView = (id) => {
    let toastId = null;
    fetchCall(`/app/api/datasource/${id}`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        this.setState({
          consolidatedDSDetails: resultData , 
          isDSConsolidatedViewOpen:true
        });
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }
  closeDSConsolidatedModal = () => {
    this.setState({
      isDSConsolidatedViewOpen:false
    })
  }
  dismissAll = () => toast.dismiss();

  getRCAImpactCount = (data_source) => {
    let toastId = null;
    let ds_obj = this.state.pipeline.filter((item)=>{
      return item.display_name == data_source.label
    })
    let id = ds_obj[0].pipeline_uuid
    fetchCall(`/app/api/anomaly/rca_impact_count/${id}/`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          rca_impact_count: resultData,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  }

  handleDataSourceChangeRCA = (data_source) => {
    if(data_source.value == ""){
      this.resetRCAParams()
    }else{
      let anomalyExprimentsRCA = this.state.anomalyExprimentsRCA
      let causalExprimentsRCA = this.state.causalExprimentsRCA
      let linked_anomaly_projects = anomalyExprimentsRCA.filter((exp)=>{
        return exp.data_source_name == data_source.value && exp.experiment_type == 'Anomaly'
      })
      let linked_causal_projects = causalExprimentsRCA.filter((exp)=>{
        return exp.data_source_name == data_source.value
      })
      if(linked_anomaly_projects.length == 0){
        const text = "Selected Datasource doesn't have Anomaly Projects/Experiments";
        this.togglePopup(text);
        this.resetRCAParams()
        return;
      }else if(linked_causal_projects.length == 0){
        const text = "Selected Datasource doesn't have Causal Projects/Experiments";
        this.togglePopup(text);
        this.resetRCAParams()
        return;
      }
      this.setState({
        rcaDisplayAnomalyProjects:linked_anomaly_projects,
        rcaDisplayCausalProjects:linked_causal_projects,
        selected_rca_anomaly_label:"",
        selected_anomaly_run:"",
        selected_rca_causal_exp_label:"",
        selected_causal_run:"",
        selected_anomaly_experiment_id:"",
        selected_causal_experiment_id:"",
        selected_anomaly_run_id:"",
        selected_causal_run_id:""
      })
      this.getRCAImpactCount(data_source)
    }
    
  }
  resetRCAParams = () => {
    this.setState({
      selected_rca_anomaly_label:"",
      selected_rca_causal_exp_label:"",
      selected_anomaly_run:"",
      selected_causal_run:"",
      predictiveKPIList:[],
      recommendationKpiList:[],
      selectedRecommenationKpis:[],
      anomalyRunsRCA:"",
      causalRunsRCA:"",
      rcaDisplayAnomalyProjects:null,
      rcaDisplayCausalProjects: null,
      selected_anomaly_experiment_id:"",
      selected_causal_experiment_id:"",
      selected_anomaly_run_id:"",
      selected_causal_run_id:"",
      recommendation_ds_id:""
    })
  }
  resetRecommendationParams  = () => {
    this.setState({
      recommendationKpiList:[],
      selectedRecommenationKpis:[],
      selected_causal_run_id:"",
      recommendation_ds_id:""
  })
  }
  handlePrivacyPolicyModal = () => { 
    this.setState({
      isPrivacyPolicyModalOpen:true
    })
  }
  closePrivacyPolicyModal = () => {
    this.setState({
      isPrivacyPolicyModalOpen:false
    })
  }
  handleDataSourceChangePredictive = (data_source) => {
    
    let data_source_id_obj = this.state.pipeline && this.state.pipeline.filter((item)=>{
      return item.display_name == data_source.label
    })
    if(data_source_id_obj && data_source_id_obj.length == 0){
      this.handleDataSourceChangeRCA(data_source)
      return ""
    }
    let data_source_id = data_source_id_obj[0].pipeline_uuid
    this.handleDataSourceChangeRCA(data_source)
    let current_kpis = data_source_id_obj[0].user_columns.kpi
    let predictiveKpiTypeMap = {}
    current_kpis.forEach((kpi)=>{
      predictiveKpiTypeMap[kpi] = "F"
    })
    let predictiveKpiList = []
    current_kpis.forEach((kpi)=>{
      let obj = {label:kpi, value:kpi}
      predictiveKpiList.push(obj)
    }) 
    this.setState({
      predictiveKPIList:data_source_id_obj[0].user_columns.kpi,
      predictiveKpiTypeMap:predictiveKpiTypeMap,
      selectedPredictiveKpis:predictiveKpiList,
      predictiveKpiType:predictiveKpiTypeMap
    })
  }
  handleDataSourceChangeRecommendation = (data_source) => {
    let data_source_id_obj = this.state.pipeline && this.state.pipeline.filter((item)=>{
      return item.display_name == data_source.label
    })
    if(data_source_id_obj && data_source_id_obj.length == 0){
      this.handleDataSourceChangeRCA(data_source)
      return ""
    }
    let data_source_id = data_source_id_obj[0].pipeline_uuid
    this.handleDataSourceChangeRCA(data_source)
    let current_kpis = data_source_id_obj[0].user_columns.kpi
   
    let actionKpiList = []
    current_kpis.forEach((kpi)=>{
      let obj = {label:kpi, value:kpi}
      actionKpiList.push(obj)
    }) 
    this.setState({
      recommendationKpiList:actionKpiList,
      recommendation_ds_id:
        data_source && data_source.value.length > 0
          ? data_source_id_obj[0].pipeline_uuid
          : null
    })
  }
  handlePredictiveKpiType = (value, type) => {
    let ckpiTypeMap = this.state.predictiveKpiTypeMap;
    ckpiTypeMap[value] = type;
    this.setState({
      predictiveKpiTypeMap: ckpiTypeMap,
    });
  }
  handlePredictiveKpiChange = (selected_kpis) => {
    let predictiveKpiType = this.state.predictiveKpiType
    this.state.predictiveKPIList.forEach((item,i)=>{
      let find_value = selected_kpis.find(x => x.value === item)
        if(find_value && Object.keys(find_value).length > 0){
          predictiveKpiType[item] = "F"
        }else{
          predictiveKpiType[item] = "NF"
        }
    })
    this.setState({
      selectedPredictiveKpis : selected_kpis,
      predictiveKpiType:predictiveKpiType
    })
  }
  handleRecommendationKpiChange = (selected_kpis) => {
    this.setState({
      selectedRecommenationKpis : selected_kpis
    })
  }
  updateTargetKpiModel = (model) => {
    this.setState({
      target_kpi_model:model
    })
  }
  handleUnivariateAlgoChange = (label) => {
    let predictiveAlgos = this.state.anomaly_algorithms && this.state.anomaly_algorithms.length> 0 && this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === 'Predictive Alert'
    );
    let paramObj = predictiveAlgos && predictiveAlgos.length>0 && predictiveAlgos.filter(
      (obj) => obj.algorithm_name === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })
    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].algorithm_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      //current_algo_id: paramObj[0].uuid,
      //current_algo: paramObj[0].algorithm_name,
      //current_algo_display_name: paramObj[0].display_name,
      selected_univariate_algo: selected_user_algo,
      uniVariateAlgoParam: param_val,
      algoData: JSON.parse(paramObj[0].algorithm_defaults),
      uniVariateAlgoParamData:algo_param_all_values,
      required_params:required_params,
      formErrors:[]
    });
  };
  handleUnivariateParamChange = (form_event) => {
    this.setState({
      uniVariateAlgoParamData: form_event.formData,
       formErrors:form_event.errors
    });
  }
  handleMultiVariateParamChange = (form_event) => {
    this.setState({
      multiVariateAlgoParamData: form_event.formData,
       formErrors:form_event.errors
    });
  }
  handleMultiVariateAlgoChange = (label) => {
    let predictiveAlgos = this.state.anomaly_algorithms && this.state.anomaly_algorithms.length> 0 && this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === 'Multivariate'
    );
    let paramObj = predictiveAlgos && predictiveAlgos.length>0 && predictiveAlgos.filter(
      (obj) => obj.algorithm_name === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })

    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].algorithm_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      //current_algo_id: paramObj[0].uuid,
      //current_algo: paramObj[0].algorithm_name,
      //current_algo_display_name: paramObj[0].display_name,
      selected_multivariate_algo: selected_user_algo,
      multiVariateAlgoParam: param_val,
      algoData: JSON.parse(paramObj[0].algorithm_defaults),
      multiVariateAlgoParamData:algo_param_all_values,
      required_params:required_params,
      formErrors:[]
    });
  };
  handleForwardLookupLimit = (evt) => {
    const fwd_lookup_limit = evt.target.validity.valid
      ? evt.target.value
      : this.state.fwd_lookup_limit;

    this.setState({
      fwd_lookup_limit,
    });
  };
  handleBackwardLookupLimit = (evt) => {
    const backward_lookup_limit = evt.target.validity.valid
      ? evt.target.value
      : this.state.backward_lookup_limit;

    this.setState({
      backward_lookup_limit,
    });
  };
  saveTargetFilesPath = (path) => {
    this.setState({
      target_kpifile_path: path
    })
  }
  run_predictive_alert = () => {
    let anomaly_exp_id = this.state.selected_anomaly_experiment_id;
    let anomaly_exp_name = this.state.selected_anomaly_experiment_name;
    let anomaly_run_id = this.state.selected_anomaly_run_id;
    let anomaly_run_name = this.state.selected_anomaly_run_name;
    let causal_exp_id = this.state.selected_causal_experiment_id;
    let causal_exp_name = this.state.selected_causal_experiment_name;
    let causal_run_id = this.state.selected_causal_run_id;
    let causal_run_name = this.state.selected_causal_run_name;
    let target_kpifile_path = this.state.target_kpifile_path && this.state.target_kpifile_path.length>0 ?
                            this.state.target_kpifile_path : ""
    let target_kpi_model = this.state.target_kpi_model && this.state.target_kpi_model.data.length > 0 ? 
                            this.state.target_kpi_model.data : ""

    let kpi_dict = this.state.predictiveKpiType

    
    let default_algorithm_rca = {}
    default_algorithm_rca[`algoType`] = this.state.algoType
    default_algorithm_rca[`selected_user_algo`] = this.state.selected_user_algo
    default_algorithm_rca[`algoParamData`] = this.state.algoParamData
    let nullFlag = false;
    let allIds = [anomaly_exp_id, anomaly_run_id, causal_exp_id, causal_run_id,this.state.forecasting_length,this.state.period_predictive,
      this.state.selectedPredictiveFrequency,this.state.selected_univariate_algo,this.state.selected_multivariate_algo,this.state.fwd_lookup_limit,this.state.backward_lookup_limit];
    allIds.forEach((id) => {
      if (id === undefined || id === null || id == "") {
        return (nullFlag = true);
      }
    });
    if (nullFlag) {
      const text = "Please select all combinations";
      this.togglePopup(text);
      return;
    }
    let toastId = null;

    toastId = toast("Predictive Alert execution", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      user_id: JSON.parse(this.state.user_uuid),
      anomaly_project_id:anomaly_exp_id,
      causal_project_id: causal_exp_id,
      causal_experiment_id: causal_run_id,    
      KPI_dictionary: kpi_dict,
      predictive_alerting_params:{
        frequency:this.state.selectedPredictiveFrequency.value,
        period: Number(this.state.period_predictive),
        forecasting_length: Number(this.state.forecasting_length),
        univariate_analysis_model:this.state.selected_univariate_algo.label,
        univariate_model_params:this.state.uniVariateAlgoParamData,
        multivariate_analysis_model:this.state.selected_multivariate_algo.label,
        multivariate_model_params:this.state.multiVariateAlgoParamData,
      },
      target_kpi_payload:target_kpi_model,
      target_kpifile_path:target_kpifile_path,
      root_cause_params:{
        forward_lookup_limit: Number(this.state.fwd_lookup_limit),
        backward_lookup_limit: Number(this.state.backward_lookup_limit)
      }
     
      //default_algorithm_rca:default_algorithm_rca
      //user_email: this.state.pipeline_user_map,
    })
    fetchCall(`/app/api/anomaly/run/${anomaly_run_id}/predictive/run`,"POST",data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          RCA_OP: resultData,
        });
        toast.update(toastId, {
          render: "Initiated Predictive Alert Run",
          type: toast.TYPE.INFO,
          autoClose: true,

        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
        });
      });
  };
  toggleRCAMenu = () => {
    let current_rca_options = this.state.isRcaOptions
    this.setState({
      isRcaOptions: !current_rca_options
    })
  }
  toggleSummerizationMenu = () => {
    let current_isSummerizationOptions = this.state.isSummerizationOptions
    this.setState({
      isSummerizationOptions: !current_isSummerizationOptions,
      isDsSummaryPage:!this.state.isDsSummaryPage
    })
  }

  authValidate = () => {
    const requestOptions = {
      method: "GET",
    };
  
    return fetch(`/app/api/auth/auth-validate`, requestOptions) 
    .then((response) => handleErrors(response))
    .then((user) => {
      if (user) {

          localStorage.setItem("email", user.data.email);
          localStorage.setItem("username", user.data.username);
          localStorage.setItem("uid", JSON.stringify(user.data.user_uuid));
          localStorage.setItem("is_auth", true);
          this.setState({ 
            isAuthenticated: true ,
            user_uuid: JSON.stringify(user.data.user_uuid)
          })

    }

  })
    .catch((error) => {
        throw error
      });
  }
  
  handleWhatIfAlgoChange = (label) => {
    let whatIfAlgos = this.state.anomaly_algorithms && this.state.anomaly_algorithms.length> 0 && this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === 'WhatIf'
    );
    let paramObj = whatIfAlgos && whatIfAlgos.length>0 && whatIfAlgos.filter(
      (obj) => obj.algorithm_name === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })
    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].algorithm_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      selected_whatif_algo: selected_user_algo,
      whatIfAlgoParam: param_val,
      algoData: JSON.parse(paramObj[0].algorithm_defaults),
      whatIfAlgoParamData:algo_param_all_values,
      required_params:required_params,
      formErrors:[]
    });
  };

  handleRecommendationAlgoChange = (label) => {
    let recommendationAlgos = this.state.anomaly_algorithms && this.state.anomaly_algorithms.length> 0 && this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === 'Recommendation'
    );
    let paramObj = recommendationAlgos && recommendationAlgos.length>0 && recommendationAlgos.filter(
      (obj) => obj.algorithm_name === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })
    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].algorithm_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      selected_recommendation_algo: selected_user_algo,
      recommendationAlgoParam: param_val,
      algoData: JSON.parse(paramObj[0].algorithm_defaults),
      recommendationAlgoParamData:algo_param_all_values,
      required_params:required_params,
      formErrors:[]
    });
  };

  handleObjectiveKpiChange = (obj) => {
    this.setState({
      selected_objective_kpi: obj
    });
  };
  handleDriverKPIChange = (values) => {
    this.setState({
      selected_driver_kpis: values
    });
  };
  updateUploadModelOption = (value) => {
    this.setState({
      whatIf_upload_or_model:value
    })
  }
  saveRegressionFilePath = (path) => {
    this.setState({
      regression_file_path:path
    })
  }
  saveModelParamFile = (path) => {
    this.setState({
      model_param_file_path:path
    })
  }
  saveFitParamFile = (path) => {
    this.setState({
      fit_param_file_path:path
    })
  }
   
 
  run_whatIf_Algo = (ds_id) => {
    let causal_exp_id = this.state.selected_causal_experiment_id;
    let causal_run_id = this.state.selected_causal_run_id;
    let driverkpis = this.state.selected_driver_kpis;
    let objectivekpi = this.state.selected_objective_kpi
    let whatIf_upload_or_model = this.state.whatIf_upload_or_model
    let regression_file_path = this.state.regression_file_path
    let model_param_file_path = this.state.model_param_file_path
    let fit_param_file_path = this.state.fit_param_file_path
    let selected_whatif_algo = this.state.selected_whatif_algo
  
    let nullFlag = false;
   
    let toastId = null;

    let data = {}
    if(whatIf_upload_or_model == 'model'){
        let allIds = [causal_exp_id, causal_run_id];
        allIds.forEach((id) => {
          if (id === undefined || id === null || id == "") {
          return (nullFlag = true);
          }
        });
        if(selected_whatif_algo == null || Object.keys(objectivekpi).length == 0 || (Object.keys(objectivekpi).length > 0 && objectivekpi.value == "") ||
          driverkpis.length == 0
        ){
          nullFlag = true
        }
        if (nullFlag) {
          const text = "Please select all combinations";
          this.togglePopup(text);
          return;
        }
        data = JSON.stringify({
        user_id: JSON.parse(this.state.user_uuid),
        data_source_id:ds_id,
        causal_project_id: causal_exp_id,
        causal_experiment_id: causal_run_id,
        objective_kpi:objectivekpi.value,
        driver_kpis:driverkpis,
        algorithm_name:selected_whatif_algo.label,
        algorithm_param:this.state.whatIfAlgoParamData,
        whatIf_upload_or_model:whatIf_upload_or_model
      })
    }else{
      if (!ds_id) {
        const text = "Please select data source.";
        this.togglePopup(text);
        return;
      }
      data = JSON.stringify({
        user_id: JSON.parse(this.state.user_uuid),
        data_source_id:ds_id,
        regression_file_path:regression_file_path,
        model_param_file_path:model_param_file_path,
        fit_param_file_path:fit_param_file_path,
        whatIf_upload_or_model:whatIf_upload_or_model
      })
    }
    toastId = toast("What If Algorithm Execution!", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    fetchCall(`/app/api/anomaly/run/${ds_id}/whatIf/run`,"POST",data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData) {
          toast.update(toastId, {
            render: "Executing What If Secnario! Please click on view button to view and download the results.",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
  
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
        });
      });
  };
  updateBusinessModel = (result) => {
    this.setState({
      recommendation_business_model:result
    })
  }
  updatePriorityKpiModel = (result) => {
    this.setState({
      recommendation_priority_model:result
    })
  }
  updateConstraintModel = (result) => {
    this.setState({
      recommendation_constraint_model:result
    })
  }
  
  handleRecommendationParamChange = (form_event) => {
    let formData = form_event.formData

    let updatedFromData = {}
    Object.entries(formData).forEach(([key, value]) => {
      if(value == undefined){
        value = ''
      }
      updatedFromData[key] = value
    });
    this.setState({
      recommendationAlgoParamData: updatedFromData,
       formErrors:form_event.errors
    });
  }

  run_action_recommendation = (ds_id) => {
    let anomaly_exp_id = this.state.selected_anomaly_experiment_id;
    let anomaly_run_id = this.state.selected_anomaly_run_id;
    let causal_exp_id = this.state.selected_causal_experiment_id;
    let causal_run_id = this.state.selected_causal_run_id;
    let algo_name = this.state.selected_recommendation_algo
    let recommendation_kpis = this.state.selectedRecommenationKpis
    let recommendation_business_model = this.state.recommendation_business_model
    let recommendationAlgoParamData = this.state.recommendationAlgoParamData
    
    console.log(recommendationAlgoParamData)
  
    let nullFlag = false;
    let allIds = [causal_exp_id, causal_run_id,anomaly_exp_id,anomaly_run_id,algo_name];
    allIds.forEach((id) => {
      if (id === undefined || id === null || id == "") {
        return (nullFlag = true);
      }
    });
    if (nullFlag) {
      const text = "Please select all combinations";
      this.togglePopup(text);
      return;
    }
   
    if(recommendation_business_model == "" || recommendation_business_model == undefined){
      const text = "Please upload business direction file";
      this.togglePopup(text);
      return;
    }
    if(recommendationAlgoParamData.gen_ai_summary && recommendationAlgoParamData.open_ai_api_key == ""){
      const text = "Open Api Key is mandatory to add for Gen AI Summary";
      this.togglePopup(text);
      return;
    }
    let toastId = null;

    toastId = toast("Action recommendation execution", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      user_id: JSON.parse(this.state.user_uuid),
      data_source_id:ds_id,
      anomaly_project_id:anomaly_exp_id,
      anomaly_experiment_id:anomaly_run_id,
      causal_project_id: causal_exp_id,
      causal_experiment_id: causal_run_id,
      algorithm_name:algo_name.label,
      recommendation_kpis:recommendation_kpis,
      algorithm_param:recommendationAlgoParamData,
      business_model:recommendation_business_model,
      priority_model:this.state.recommendation_priority_model,
      constraint_model:this.state.recommendation_constraint_model
    })
   
    fetchCall(`/app/api/graph/action_recommendation/run`,"POST",data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Initiated Action Recommendation Run",
          type: toast.TYPE.INFO,
          autoClose: true,

        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
        });
      });
  };

  render() {
    let login_tag = <p>please <a onClick={()=>window.location.href = '/' }>login</a> here</p>
    const contextValue = { store_user_uuid: this.state.user_uuid}
    return  (
      this.state.isAuthenticated ?
      <Route>
        <div className="body">
          <nav className="col-1">
            <div className="logo_nav">
              <img alt="bi2i-logo" src={logo} />
            </div>
            <ul className="main-nav">
              <li>
                <NavLink to={`/app/datasource/`}>Data Source</NavLink>
              </li>
              <li>
                <NavLink to={`/app/anomaly/experiment`} activeClassName="active">
                  Anomaly
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/causal/experiment`}>Causal</NavLink>
              </li>
              {/* <li>
                <NavLink to={`/app/alert_summerization`}>Alert Summerization</NavLink>
              </li> */}
              <li>
                <a onClick={()=>this.toggleRCAMenu()}>
                  RCA <span style={{"float":"right"}}><i className={this.state.isRcaOptions ? 'fa fa-angle-down' : 'fa fa-angle-right'}></i></span>
                </a>
                <ul className={this.state.isRcaOptions ? 'show-rca-options' : 'hide-rca-options'} style={{"paddingLeft":"20px"}}>
                  <li><NavLink exact={true} to={`/app/rca`}>
                      RCA &amp; Impact Analysis
                  </NavLink></li>
                  <li><NavLink exact={true} to={`/app/predictive_alert`}>
                      Predictive Alert
                  </NavLink></li>
                </ul>
              </li>
              <li>
                <a onClick={()=>this.toggleSummerizationMenu()}>
                  Summerization <span style={{"float":"right"}}><i className={this.state.isSummerizationOptions ? 'fa fa-angle-down' : 'fa fa-angle-right'}></i></span>
                </a>
                <ul className={this.state.isSummerizationOptions ? 'show-rca-options' : 'hide-rca-options'} style={{"paddingLeft":"20px"}}>
                  <li><NavLink exact={true} to={`/app/alert_summerization`}>
                      Alert Summerization
                  </NavLink></li>
                  <li><NavLink exact={true} to={`/app/anomaly/ds/experiment`}>
                      Descriptive Summary
                  </NavLink></li>
                </ul>
              </li>
              <li>
                <NavLink to={`/app/whatif`} activeClassName="active">
                  What If Scenario
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/recommendation`} activeClassName="active">
                    Action Recommendation
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/feedback`} activeClassName="active">
                  Feedback Mining
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/csvreports`} activeClassName="active">
                  CSV Reports
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/externaldatacopy`} activeClassName="active">
                  Cloud Data Copy
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="col-2">
            <header>
              <div className="title">
                <h2>KPI Watchtower</h2>
              </div>
              <div className="navbar">
                
                <ul className="nav-links header-options">
                  
                   <li className="nav-item">
                    <NavLink exact={true} to={`/app/archive`}>
                      <span><i className="fa fa-archive"></i></span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                  <NavLink exact={true} className="notifications" to={`/app/notifications`}>
                    <i className="fa fa-bell"></i>
                    <span className="button__badge">{this.state.notification_count || 0}</span>
                  </NavLink>
                  </li>
                 
                  <li className="nav-item">
                    <NavLink exact={true} to={`/app/faq`}>
                      <span>FAQ</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact={true} to={`/`} onClick={()=>userService.logout()}>
                      Logout
                    </NavLink>
                  </li>
                  <li className="nav-item">
                  <NavLink exact={true} to={`/`} onClick={(e)=>e.preventDefault()}>
                  Welcome {localStorage.username}
                  </NavLink>
                  </li>
                </ul>
              </div>
            </header>
            <main className="content">
              <div id="main">
                <div className="test"></div>
                <article>
                  <div className="analyst-view">
                    <div className="perf-panel"></div>
                    <div>
                      {this.state.showPopup ? (
                        <Popup
                          text={this.state.popuptext}
                          closePopup={this.togglePopup.bind(this)}
                        />
                      ) : null}
                    </div>
                    <div className="train-ctrl">
                      <content>
                      <AppProvider value={contextValue}>
                      <Switch>
                        <ProtectedRoute
                          exact
                          path="/app/datasource/*"
                          component={DataSource}
                          togglePopup={this.togglePopup}
                          fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                          anomalyExprimentsRCA={this.state.anomalyExprimentsRCA}
                          fetchCausalExperiments={this.fetchCausalExperiments}
                          causalExprimentsRCA={this.state.causalExprimentsRCA}
                        />
                        <ProtectedRoute
                          exact
                          path="/app/causal/*"
                          component={Causal}
                          fetchDataSources={this.fetchDataSources}
                          pipeline={this.state.pipeline}
                          togglePopup={this.togglePopup}
                          handleDataSourceView={this.handleDataSourceView}
                          isDSConsolidatedViewOpen={this.state.isDSConsolidatedViewOpen}
                          closeDSConsolidatedModal={this.closeDSConsolidatedModal}
                          consolidatedDSDetails={this.state.consolidatedDSDetails}
                        />

                        <ProtectedRoute
                          exact
                          path="/app/anomaly/*"
                          component={Anomaly}
                          fileData={this.state.fileData}
                          togglePopup={this.togglePopup}
                          pipeline={this.state.pipeline}
                          handleDataSourceView={this.handleDataSourceView}
                          isDSConsolidatedViewOpen={this.state.isDSConsolidatedViewOpen}
                          closeDSConsolidatedModal={this.closeDSConsolidatedModal}
                          consolidatedDSDetails={this.state.consolidatedDSDetails}
                          isDsSummaryPage={this.state.isDsSummaryPage}
                        />
                         <ProtectedRoute
                          exact
                          path="/app/anomaly/ds/*"
                          component={Anomaly}
                          fileData={this.state.fileData}
                          togglePopup={this.togglePopup}
                          pipeline={this.state.pipeline}
                          handleDataSourceView={this.handleDataSourceView}
                          isDSConsolidatedViewOpen={this.state.isDSConsolidatedViewOpen}
                          closeDSConsolidatedModal={this.closeDSConsolidatedModal}
                          consolidatedDSDetails={this.state.consolidatedDSDetails}
                          isDsSummaryPage={this.state.isDsSummaryPage}
                        />
                        <ProtectedRoute
                          exact={true}
                          path="/app/alert_summerization/"
                          component={Summerization}
                          fetchDataSources={this.fetchDataSources}
                          datasourcesList={this.state.pipeline}
                          togglePopup={this.togglePopup}
                          fetchCausalExperiments={this.fetchCausalExperiments}
                          fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                          anomalyExpriments={this.state.anomalyExprimentsRCA}
                          causalExpriments={this.state.causalExprimentsRCA}
                          handleDataSourceChangeRCA={this.handleDataSourceChangeRCA}
                          rcaDisplayAnomalyProjects={this.state.rcaDisplayAnomalyProjects}
                          rcaDisplayCausalProjects={this.state.rcaDisplayCausalProjects}
                          selected_rca_anomaly_label={
                            this.state.selected_rca_anomaly_label
                          }
                          handleAnomalyExpCallRCA={this.handleAnomalyExpCallRCA}
                          anomalyRunsRCA={this.state.anomalyRunsRCA}
                          selected_anomaly_run={this.state.selected_anomaly_run}
                          handleSelectAnomalyRunRCA={this.handleSelectAnomalyRunRCA}
                          causalRunsRCA={this.state.causalRunsRCA}
                          selected_causal_run={this.state.selected_causal_run}
                          handleSelectCausalRunRCA={this.handleSelectCausalRunRCA}
                          selected_rca_causal_exp_label={this.state.selected_rca_causal_exp_label}
                          handleCausalExpCallRCA={this.handleCausalExpCallRCA}
                          selected_anomaly_experiment_id={this.state.selected_anomaly_experiment_id}
                          selected_anomaly_run_id={this.state.selected_anomaly_run_id}
                          selected_causal_experiment_id={this.state.selected_causal_experiment_id}
                          selected_causal_run_id={this.state.selected_causal_run_id}                          
                        />
                        <ProtectedRoute
                          exact
                          path="/app/rca/:pipeline_id?"
                          component={RCA}
                          run_rca={this.run_rca}
                          RCA_OP={this.state.RCA_OP}
                          users={this.state.users}
                          map_user={this.map_user}
                          pipeline_user_map={this.state.pipeline_user_map}
                          pipelineName={this.state.pipelineName}
                          fetchCausalExperiments={this.fetchCausalExperiments}
                          causalExprimentsRCA={this.state.causalExprimentsRCA}
                          handleCausalExpCallRCA={this.handleCausalExpCallRCA}
                          causalRunsRCA={this.state.causalRunsRCA}
                          handleSelectCausalRunRCA={
                            this.handleSelectCausalRunRCA
                          }
                          fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                          anomalyExprimentsRCA={this.state.anomalyExprimentsRCA}
                          handleAnomalyExpCallRCA={this.handleAnomalyExpCallRCA}
                          anomalyRunsRCA={this.state.anomalyRunsRCA}
                          handleSelectAnomalyRunRCA={
                            this.handleSelectAnomalyRunRCA
                          }
                          rcaMaxHopsSchema={this.state.rcaMaxHopsSchema}
                          maxHopsData={this.state.maxHopsData}
                          handleRcaMaxHopsChange={this.handleRcaMaxHopsChange}
                          selected_rca_anomaly_label={
                            this.state.selected_rca_anomaly_label
                          }
                          selected_rca_causal_exp_label={
                            this.state.selected_rca_causal_exp_label
                          }
                          handleLookupRange={this.handleLookupRange}
                          lookup_range={this.state.lookup_range}
                          fetchUsers={this.fetchUsers}
                          traversal_kpis_list={this.state.traversal_kpis_list}
                          handleRcaKpiSelection={this.handleRcaKpiSelection}
                          traversal_kpis={this.state.traversal_kpis}
                          getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                          anomaly_algo_types={this.state.anomaly_algo_types}
                          handleAlgorithmTypeChange={this.handleAlgorithmTypeChange}
                          handleAlgorithmChange={this.handleAlgorithmChange}
                          algoType={this.state.algoType}
                          selected_user_algo={this.state.selected_user_algo}
                          userAlgo={this.state.userAlgo}
                          algoParam={this.state.algoParam}
                          algoParamData={this.state.algoParamData}
                          handleParamChange={this.handleParamChange}
                          datasourcesListRCA={this.state.pipeline}
                          fetchDataSources={this.fetchDataSources}
                          handleDataSourceChangeRCA={this.handleDataSourceChangeRCA}
                          selected_anomaly_run={this.state.selected_anomaly_run}
                          selected_causal_run={this.state.selected_causal_run}
                          rcaDisplayAnomalyProjects={this.state.rcaDisplayAnomalyProjects}
                          rcaDisplayCausalProjects={this.state.rcaDisplayCausalProjects}
                          rca_impact_count={this.state.rca_impact_count}
                        />
                         <ProtectedRoute
                          exact
                          path="/app/predictive_alert/:pipeline_id?"
                          component={PredictiveComponent}
                          run_rca={this.run_rca}
                          RCA_OP={this.state.RCA_OP}
                          users={this.state.users}
                          map_user={this.map_user}
                          pipeline_user_map={this.state.pipeline_user_map}
                          pipelineName={this.state.pipelineName}
                          fetchCausalExperiments={this.fetchCausalExperiments}
                          causalExprimentsRCA={this.state.causalExprimentsRCA}
                          handleCausalExpCallRCA={this.handleCausalExpCallRCA}
                          causalRunsRCA={this.state.causalRunsRCA}
                          handleSelectCausalRunRCA={
                            this.handleSelectCausalRunRCA
                          }
                          fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                          anomalyExprimentsRCA={this.state.anomalyExprimentsRCA}
                          handleAnomalyExpCallRCA={this.handleAnomalyExpCallRCA}
                          anomalyRunsRCA={this.state.anomalyRunsRCA}
                          handleSelectAnomalyRunRCA={
                            this.handleSelectAnomalyRunRCA
                          }
                          rcaMaxHopsSchema={this.state.rcaMaxHopsSchema}
                          maxHopsData={this.state.maxHopsData}
                          handleRcaMaxHopsChange={this.handleRcaMaxHopsChange}
                          selected_rca_anomaly_label={
                            this.state.selected_rca_anomaly_label
                          }
                          selected_rca_causal_exp_label={
                            this.state.selected_rca_causal_exp_label
                          }
                          handleLookupRange={this.handleLookupRange}
                          lookup_range={this.state.lookup_range}
                          fetchUsers={this.fetchUsers}
                          traversal_kpis_list={this.state.traversal_kpis_list}
                          handleRcaKpiSelection={this.handleRcaKpiSelection}
                          traversal_kpis={this.state.traversal_kpis}
                          getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                          anomaly_algo_types={this.state.anomaly_algo_types}
                          handleAlgorithmTypeChange={this.handleAlgorithmTypeChange}
                          handleAlgorithmChange={this.handleAlgorithmChange}
                          algoType={this.state.algoType}
                          selected_user_algo={this.state.selected_user_algo}
                          userAlgo={this.state.userAlgo}
                          algoParam={this.state.algoParam}
                          algoParamData={this.state.algoParamData}
                          handleParamChange={this.handleParamChange}
                          datasourcesListRCA={this.state.pipeline}
                          fetchDataSources={this.fetchDataSources}
                          handleDataSourceChangeRCA={this.handleDataSourceChangeRCA}
                          selected_anomaly_run={this.state.selected_anomaly_run}
                          selected_causal_run={this.state.selected_causal_run}
                          handleDataSourceChangePredictive={this.handleDataSourceChangePredictive}
                          rcaDisplayAnomalyProjects={this.state.rcaDisplayAnomalyProjects}
                          rcaDisplayCausalProjects={this.state.rcaDisplayCausalProjects}
                          predictiveKPIList={this.state.predictiveKPIList}
                          run_predictive_alert={this.run_predictive_alert}
                          predictiveKpiTypeMap={this.state.predictiveKpiTypeMap}
                          handlePredictiveKpiType={this.handlePredictiveKpiType}
                          handlePredictiveKpiChange={this.handlePredictiveKpiChange}
                          handleForeCastingLength={this.handleForeCastingLength}
                          forecasting_length={this.state.forecasting_length}
                          period_predictive={this.state.period_predictive}
                          handlePredictivePeriod={this.handlePredictivePeriod}
                          updateTargetKpiModel={this.updateTargetKpiModel}
                          anomaly_algorithms={this.state.anomaly_algorithms}
                          handleUnivariateAlgoChange={this.handleUnivariateAlgoChange}
                          handleMultiVariateAlgoChange={this.handleMultiVariateAlgoChange}
                          selected_univariate_algo={this.state.selected_univariate_algo}
                          selected_multivariate_algo={this.state.selected_multivariate_algo}
                          multiVariateAlgoParam={this.state.multiVariateAlgoParam}
                          multiVariateAlgoParamData={this.state.multiVariateAlgoParamData}
                          uniVariateAlgoParam={this.state.uniVariateAlgoParam}
                          uniVariateAlgoParamData={this.state.uniVariateAlgoParamData}
                          handleUnivariateParamChange={this.handleUnivariateParamChange}
                          handleMultiVariateParamChange={this.handleMultiVariateParamChange}
                          selectedPredictiveKpis={this.state.selectedPredictiveKpis}
                          selectedPredictiveFrequency={this.state.selectedPredictiveFrequency}
                          handlePredictiveFrequency={this.handlePredictiveFrequency}
                          handleForwardLookupLimit={this.handleForwardLookupLimit}
                          fwd_lookup_limit={this.state.fwd_lookup_limit}
                          handleBackwardLookupLimit={this.handleBackwardLookupLimit}
                          backward_lookup_limit={this.state.backward_lookup_limit}
                          saveTargetFilesPath={this.saveTargetFilesPath}
                        />
                        <ProtectedRoute exact={true} path="/app/feedback" component={FeedbackMining} />
                        <ProtectedRoute exact={true} path="/app/csvreports" component={CSVReportHandler} />
                        <ProtectedRoute exact={true} path="/app/externaldatacopy" component={ExternalDataCopier}/>
                        <ProtectedRoute exact={true} path="/app/whatif" component={WhatIfScreen}
                        fetchDataSources={this.fetchDataSources}
                        datasourcesList={this.state.pipeline} 
                        fetchCausalExperiments={this.fetchCausalExperiments}
                        rcaDisplayCausalProjects={this.state.rcaDisplayCausalProjects}
                        causalRunsRCA={this.state.causalRunsRCA}
                        handleDataSourceChangeRCA={this.handleDataSourceChangeRCA}
                        handleCausalExpCallRCA={this.handleCausalExpCallRCA}
                        handleSelectCausalRunRCA={
                          this.handleSelectCausalRunRCA
                        }
                        selected_rca_causal_exp_label={this.state.selected_rca_causal_exp_label}
                        selected_causal_run={this.state.selected_causal_run}
                        fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                        getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                        anomaly_algorithms={this.state.anomaly_algorithms}
                        handleWhatIfAlgoChange={this.handleWhatIfAlgoChange}
                        selected_whatif_algo={this.state.selected_whatif_algo}
                        whatIfAlgoParam={this.state.whatIfAlgoParam}
                        whatIfAlgoParamData={this.state.whatIfAlgoParamData}
                        run_whatIf_Algo={this.run_whatIf_Algo}
                        handleObjectiveKpiChange={this.handleObjectiveKpiChange}
                        selected_objective_kpi={this.state.selected_objective_kpi}
                        selected_driver_kpis = {this.state.selected_driver_kpis}
                        handleDriverKPIChange={this.handleDriverKPIChange}
                        updateUploadModelOption={this.updateUploadModelOption}
                        whatIf_upload_or_model={this.state.whatIf_upload_or_model}
                        saveRegressionFilePath={this.saveRegressionFilePath}
                        saveModelParamFile={this.saveModelParamFile}
                        saveFitParamFile={this.saveFitParamFile}
                        view_whatIf_Algo={this.view_whatIf_Algo}
                        />
                        <ProtectedRoute exact={true} path="/app/recommendation" component={ActionRecommendation}
                        fetchDataSources={this.fetchDataSources}
                        datasourcesList={this.state.pipeline} 
                        fetchCausalExperiments={this.fetchCausalExperiments}
                        rcaDisplayCausalProjects={this.state.rcaDisplayCausalProjects}
                        causalRunsRCA={this.state.causalRunsRCA}
                        handleDataSourceChangeRCA={this.handleDataSourceChangeRCA}
                        handleDataSourceChangeRecommendation={this.handleDataSourceChangeRecommendation}
                        handleCausalExpCallRCA={this.handleCausalExpCallRCA}
                        handleSelectCausalRunRCA={
                          this.handleSelectCausalRunRCA
                        }
                        selected_rca_causal_exp_label={this.state.selected_rca_causal_exp_label}
                        selected_causal_run={this.state.selected_causal_run}
                        fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                        getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                        anomaly_algorithms={this.state.anomaly_algorithms}
                        handleRecommendationAlgoChange={this.handleRecommendationAlgoChange}
                        selected_whatif_algo={this.state.selected_whatif_algo}
                        recommendationAlgoParam={this.state.recommendationAlgoParam}
                        recommendationAlgoParamData={this.state.recommendationAlgoParamData}
                        rcaDisplayAnomalyProjects={this.state.rcaDisplayAnomalyProjects}
                        handleAnomalyExpCallRCA={this.handleAnomalyExpCallRCA}
                        anomalyRunsRCA={this.state.anomalyRunsRCA}
                        run_action_recommendation={this.run_action_recommendation}
                        handleSelectAnomalyRunRCA={this.handleSelectAnomalyRunRCA}
                        selected_recommendation_algo={this.state.selected_recommendation_algo}
                        updateBusinessModel={this.updateBusinessModel}
                        updatePriorityKpiModel={this.updatePriorityKpiModel}
                        updateConstraintModel={this.updateConstraintModel}
                        business_model = {this.state.business_model}
                        priority_model = {this.state.priority_model}
                        constraint_model = {this.state.constraint_model}
                        selected_rca_anomaly_label={
                          this.state.selected_rca_anomaly_label
                        }
                        selected_anomaly_run={this.state.selected_anomaly_run}
                        handleRecommendationParamChange={this.handleRecommendationParamChange}
                        recommendationKpiList={this.state.recommendationKpiList}
                        handleRecommendationKpiChange={this.handleRecommendationKpiChange}
                        selectedRecommenationKpis={this.state.selectedRecommenationKpis}
                        recommendation_ds_id={this.state.recommendation_ds_id}
                        resetRecommendationParams={this.resetRecommendationParams}
                        />
                        <ProtectedRoute exact={true} path="/app/faq" component={FAQ} />
                        <ProtectedRoute exact={true} path="/app/archive" component={ArchievedDataSourcesList} />
                        <ProtectedRoute
                          exact={true}
                          path="/app/notifications"
                          component={Notifications}
                        />
                        <ProtectedRoute
                          exact={true}
                          path="/app/analyst/user_profile"
                          render={() => <Profiles getUsers={this.getUsers} />}
                        ></ProtectedRoute>
                        {/* <ProtectedRoute render={() => <PageNotFound />} /> */}
                        {/* <Route  path="*"  exact={true}  component={PageNotFound} /> */}
                        {/* <Route  path="/*" component={PageNotFound} /> */}
                        <Route path="/app/404"   component={PageNotFound} />
                        <Redirect to="/app/404" />
                        </Switch>
                        </AppProvider>
                      </content>
                    </div>
                  </div>
                </article>
              </div>
            </main>
            <footer>
              <div className="footer-content">
                {/* <div className="copy-right">
                  <span> &copy; 2020</span>
                </div>
                <div className="orgo-name">
                  Copyright 2002-2023 Accenture. All rights reserved. 
                </div> */}
                <p>
                  Copyright 2001-2022 Accenture. All rights reserved. Accenture Confidential. For internal use only.
                  <button role="button" class="footer-terms-btn">
                    Terms of Use
                  </button> |
                  <button onClick={this.handlePrivacyPolicyModal}  role="button" class="footer-terms-btn">
                    Legal Notice
                  </button>
                </p>
              </div>
              <PrivacyPolicyModal
                isPrivacyPolicyModalOpen={this.state.isPrivacyPolicyModalOpen}
                closePrivacyPolicyModal={this.closePrivacyPolicyModal}
              />
            </footer>
          </div>
        </div>
      </Route>
      : login_tag
    );
  }
}
