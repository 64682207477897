import React, { Component } from 'react';
import Form from 'react-jsonschema-form';
import { fetchCall } from './util/util';
import { toast } from 'react-toastify';
import { handleErrors } from './ui_utils';
import AppContext from "./context/AppContext";
import Select from 'react-select';


export class CSVReportHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportType:null,
      dataSourceId:null,
    }
  }
  static contextType = AppContext
  componentDidMount() {
    
  }

  setReportType = (chosenType) => {
    this.setState({
        selectedReportType: chosenType,
    });
  }

  setDataSourceId = (dsId) => {
    this.setState({
        dataSourceId: dsId,
    });
  }
  
  generateReport = () => {
    const requestOptions = {
        method: 'POST'
    };
    //let url = "http://0.0.0.0:5008/app/api/inference/csv_report/"+this.state.selectedReportType+"/generate/"+this.state.dataSourceId;
    let url = "/app/api/inference/csv_report/"+this.state.selectedReportType+"/generate/"+this.state.dataSourceId;
    fetch(url, requestOptions)
    .then((response) => response.text())
    .then((text) => {
      alert(text);
    });
  }

  downloadReport = () => {
    const requestOptions = {
        method: 'POST'
    };
    //let url = "http://0.0.0.0:5008/app/api/inference/csv_report/"+this.state.selectedReportType+"/download/"+this.state.dataSourceId;
    let url = "/app/api/inference/csv_report/"+this.state.selectedReportType+"/download/"+this.state.dataSourceId;
    fetch(url, requestOptions)
    .then((result) => {
        if (!result.ok) {
            if(result.status==400){
              // There are no rows for the report in database
              alert("Report is empty");
            }
            else if(result.status==404){
              //File is still being created or generate not triggered
              alert("No report file found!! Please trigger Generate or wait for sometime to retry");
            }
            return;
        }
        return result.blob();
    })
    .then((blob) => {
        if (blob != null) {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = this.state.selectedReportType+"_report.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    })
    .catch((err) =>
    {
        console.log(err);
    });
  }

  render() {
    return (
      <div className='csv-report-main'>
        <label for="report_type">Choose a report type</label>
        <select name="report_type" id="report_type" onChange={(e)=>{this.setReportType(e.target.value);}}>
            <option disabled selected value> -- Select a type of report -- </option>
            <option value="alert">Alert</option>
            <option value="rca">Root Cause Analysis</option>
            <option value="predictive">Predictive Alert</option>
            <option value="alertsummary">Alert Summary</option>
            <option value="impact">Impact Report</option>
        </select>
        <label for="ds_id">Data Source ID</label>
        <input type='text' onChange={(e)=>{this.setDataSourceId(e.target.value)}}/>
        <p>&nbsp;</p>
        
        <button style={{backgroundColor: 'green', border:'0px', padding:'10px', borderRadius:'5px', margin:'10px', color: 'white'}} 
        onClick={()=>{this.generateReport()}}>Genertate Report</button>
        
        <button style={{backgroundColor: 'red', border:'0px', padding:'10px', borderRadius:'5px', margin:'10px', color: 'white'}} 
        onClick={()=>{this.downloadReport()}}>Download Report</button>
      </div>
    );
  }
}
