import React, { Component } from 'react';
import Form from 'react-jsonschema-form';
import { fetchCall } from './util/util';
import { toast } from 'react-toastify';
import { handleErrors } from './ui_utils';
import AppContext from "./context/AppContext";
import Select from 'react-select';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class AzureBlobCopyParams extends Component{
  constructor(props) {
    super(props);
    this.state = {
      blobDirName: "",
      destDirectory : ""
    }
  }

  setBlobDirName = (blobDirectoryName) => {
    this.setState({
      blobDirName: blobDirectoryName,
    });
  }

  setDestDir = (destDir) => {
    this.setState({
      destDirectory: destDir,
    });
  }
  
  uploadToProjectAsset = () => {
    if(!this.state.destDirectory.startsWith("/project_asset/")){
      alert("Destination directory should be part of /project_asset/")
      return;
    }
    else if(this.state.destDirectory=="/project_asset/"){
      alert("You need to specify a subdirectory of /project_asset/")
      return;
    }    
    const url = "/app/api/datasource/copyfromexternal/azureblob";
    const payload = {
      "blob_dir_name": this.state.blobDirName,
      "destination_dir_name": this.state.destDirectory
    };

    const token = cookies.get('Authorization');

    toast("Uploading now.... Please Wait", { type: toast.TYPE.INFO})

    fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `${token}`,
      },
    }).then(response => response.json())
    .then(response=>{
      if(response["success"]){
        toast(response["message"], { type: toast.TYPE.SUCCESS})
      }else{
        toast(response["message"], { type: toast.TYPE.ERROR })
      }
    })
  }

  render(){
    return (
      <div>
        <div>
          <h3> Blob Directory Name : <input type='text' placeholder='Enter Blob Directory Name...' onChange={(e)=>{this.setBlobDirName(e.target.value);}} /></h3>
        </div>
        <p></p>
        <div> 
          <h3> Destination folder name : <input type='text' placeholder='/project_asset/.../' onChange={(e)=>{this.setDestDir(e.target.value);}} /></h3>
        </div>
        <p></p>
        <button style={{backgroundColor: 'green', border:'0px', padding:'10px', borderRadius:'5px', margin:'10px', color: 'white'}}
        onClick={()=>{this.uploadToProjectAsset()}}>Upload</button>
      </div>
    );
  }
}

export class ExternalDataCopier extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  static contextType = AppContext
  componentDidMount() {
    
  }

  render() {
    return (
      <div className='external-copier-main'>
        <div>
          <h2> Azure Blob Storage...</h2>
          <p></p>
          <AzureBlobCopyParams/>
        </div>
      </div>
    );
  }
}
